import { createContext, useContext, useState, useEffect } from 'react';

import Script from 'next/script';

const KoinContext = createContext();

function KoinProvider({ children }) {
  const [fraudId, setFraudId] = useState(null);
  const koinFrame = document ? document.getElementById('koin-frame') : null;
  useEffect(() => {
    if (window?.GetKoinFraudID) window?.GetKoinFraudID(guid => setFraudId(guid));
    if (koinFrame) {
      koinFrame.style.visibility = 'hidden';
      koinFrame.style.pointerEvents = 'none';
    }
  }, [koinFrame]);
  return (
    <KoinContext.Provider value={{ fraudId }}>
      {children}
      <Script
        id="koinfraudid"
        src="https://resources.koin.com.br/scripts/koin.min.js"
        onLoad={() =>
          window?.GetKoinFraudID ? window?.GetKoinFraudID(guid => setFraudId(guid)) : null
        }
      />
    </KoinContext.Provider>
  );
}

function useKoin() {
  const context = useContext(KoinContext);
  if (context === undefined) return null;
  return context;
}

export { KoinProvider, useKoin };
