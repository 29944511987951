/* eslint-disable sonarjs/no-duplicate-string */
import { common } from '@material-ui/core/colors';

const branding = {
  blue: {
    light: '#4cc4ff',
    main: '#0aafff',
    dark: '#0880ba',
  },
  red: {
    light: '#e78584',
    main: '#de5857',
    dark: '#a24140',
  },
  yellow: {
    light: '#fadd95',
    main: '#f7c13e',
    dark: '#e1b039',
  },
  orange: {
    light: '#ffb8a0',
    main: '#ff7d51',
    dark: '#e8724a',
  },
  green: {
    light: '#bbdf8f',
    main: '#83c632',
    dark: '#78b42e',
  },
  purple: {
    light: '#ac64e4',
    main: '#8e2adb',
    dark: '#681fa0',
  },
};

export const light = {
  alternate: {
    main: 'rgb(247, 249, 250)',
    dark: '#e8eaf6',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  type: 'light',
  primary: {
    main: '#0aadff',
    contrastText: common.white,
  },
  secondary: {
    main: '#008ed6',
    contrastText: common.white,
  },
  info: {
    main: '#2196f3',
    contrastText: common.white,
  },
  warning: {
    main: '#ff9800',
    contrastText: common.white,
  },
  success: {
    main: '#4caf50',
    contrastText: common.white,
  },
  error: {
    main: '#f44336',
    contrastText: common.white,
  },
  text: {
    primary: '#2d3748',
    secondary: '#718096',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: common.white,
    default: common.white,
    level2: '#f9fafc',
    level1: common.white,
    footer: '#044a6d',
    appBar: 'rgba(255, 255, 255, 0.75)',
  },
  branding,
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.04,
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(0, 0, 0, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
};

export const dark = {
  alternate: {
    main: '#2D3748',
    dark: '#24242b',
  },
  cardShadow: 'rgba(0, 0, 0, .11)',
  common: {
    black: '#000',
    white: common.white,
  },
  type: 'dark',
  primary: {
    main: '#90caf9',
    light: 'rgb(166, 212, 250)',
    dark: 'rgb(100, 141, 174)',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  secondary: {
    light: '#ffb74d',
    main: '#f9b934',
    dark: '#f57c00',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#EEEEEF',
    secondary: '#AEB0B4',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: '#1A202C',
    default: '#121212',
    level2: '#333',
    level1: '#2D3748',
    footer: '#18181f',
    appBar: 'rgba(18, 18, 18, 0.75)',
  },
  branding,
  action: {
    active: common.white,
    hover: 'rgba(255, 255, 255, 0.08)',
    hoverOpacity: 0.08,
    selected: 'rgba(255, 255, 255, 0.16)',
    selectedOpacity: 0.16,
    disabled: 'rgba(255, 255, 255, 0.3)',
    disabledBackground: 'rgba(255, 255, 255, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(255, 255, 255, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.24,
  },
};
