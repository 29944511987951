import { makeStyles } from '@material-ui/core/styles';

import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  snackbar: {
    boxShadow: '0 0.5rem 2rem 2px rgba(116, 123, 144, 0.3)',
  },
}));

function NotistackProvider({ children }) {
  const classes = useStyles();

  return (
    <SnackbarProvider
      maxSnack={3}
      preventDuplicate
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      className={classes.snackbar}>
      {children}
    </SnackbarProvider>
  );
}

NotistackProvider.propTypes = {
  children: PropTypes.any,
};

export default NotistackProvider;
