// eslint-disable-next-line import/no-anonymous-default-export
export default {
  MuiModal: {
    styleOverrides: {
      root: {
        zIndex: 1000000,
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        boxShadow: '0 0.5rem 2rem 2px rgba(116, 123, 144, 0.09)',
      },
    },
  },
  MuiBackdrop: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgba(255,255,255,0.5)',
      },
    },
  },
  MuiChip: {
    root: {
      backgroundColor: 'rgba(0,0,0,0.075)',
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontWeight: 'bold',
      },
    },
    variants: [
      {
        props: { variant: 'contained' },
        style: {
          boxShadow: 'none',
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          borderWidth: `2px !important`,
        },
      },
    ],
  },
};
