import React, { useState, useEffect } from 'react';

import { useMediaQuery, Box, Button, Link, Portal, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Cookies from 'js-cookie';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    maxWidth: 600,
    position: 'fixed',
    bottom: 0,
    left: 0,
    margin: theme.spacing(2),
    padding: theme.spacing(1.5),
    outline: 'none',
    zIndex: 1000000,
    border: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRadius: 0,
      borderTop: `1px solid ${theme.palette.divider}`,
      margin: 0,
    },
  },
}));

function CookiesNotification() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), { defaultMatches: true });

  const handleClose = () => {
    Cookies.set('consent', 'true');

    setOpen(false);
  };

  useEffect(() => {
    const consent = Cookies.get('consent');

    if (!consent) {
      setOpen(true);
    }
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div className={classes.root}>
        <Typography variant="h6" component="div" color="primary" gutterBottom>
          Privacidade
        </Typography>
        <Typography variant="body1" color="inherit">
          Utilizamos cookies para personalizar sua experiência em nossa plataforma. Ao continuar
          navegando, você concorda com a nossa{' '}
          <Link component="a" color="primary" underline="always" href="/privacy" target="_blank">
            Política de Privacidade
          </Link>
          .
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: isSm ? 'flex-end' : 'center' }}>
          <Button
            onClick={handleClose}
            color="secondary"
            variant="contained"
            size="large"
            aria-label="Continuar e fechar"
            fullWidth={!isSm}>
            Continuar e fechar
          </Button>
        </Box>
      </div>
    </Portal>
  );
}

export default CookiesNotification;
