import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

// eslint-disable-next-line no-unused-vars
function StoreThemeProvider({ store, children, themeMode }) {
  const palette = {
    light: store?.theme?.lightPalette,
    dark: store?.theme?.darkPalette || store?.theme?.lightPalette,
  };

  if (!store) return children;

  return (
    <ThemeProvider
      theme={theme =>
        // TODO: Must work on a better darkmode that fits all pages (...palette[themeMode])
        createMuiTheme({ ...theme, palette: { ...theme.palette, ...palette.light } })
      }>
      {children}
    </ThemeProvider>
  );
}

StoreThemeProvider.propTypes = {
  children: PropTypes.any,
  store: PropTypes.object,
  themeMode: PropTypes.string.isRequired,
};

export default StoreThemeProvider;
