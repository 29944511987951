import { useState, useEffect, Fragment } from 'react';

import Link from 'next/link';

import { Paper, Box, Alert, AlertTitle, Button } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import AOS from 'aos';
import PropTypes from 'prop-types';

import { CookiesNotification } from '@/src/components/widgets';
import NotistackProvider from '@/src/containers/commons/NotistackProvider';
import CheckoutLayout from '@/src/layouts/CheckoutLayout';
import getTheme from '@/src/theme';

export const useDarkMode = () => {
  const [themeMode, setTheme] = useState('light');
  const [mountedComponent, setMountedComponent] = useState(false);

  const setMode = mode => {
    window.localStorage.setItem('themeMode', mode);
    setTheme(mode);
  };

  const themeToggler = () => (themeMode === 'light' ? setMode('dark') : setMode('light'));

  useEffect(() => {
    const localTheme = window.localStorage.getItem('themeMode');
    // eslint-disable-next-line no-unused-expressions
    localTheme ? setTheme(localTheme) : setMode('light');
    setMountedComponent(true);
    AOS.refresh();
  }, []);

  useEffect(() => {
    AOS.refresh();
  }, [themeMode]);

  return [themeMode, themeToggler, mountedComponent];
};

const PreviewBox = () => (
  <Box display="flex" justifyContent="center">
    <Alert
      style={{
        position: 'fixed',
        bottom: '5%',
        zIndex: 999,
      }}
      color="warning"
      action={
        <Box display="flex" alignSelf="center">
          <Link href="/api/prismic/exit-preview" passHref>
            <Button component="a" color="inherit" size="small">
              Sair
            </Button>
          </Link>
        </Box>
      }>
      <AlertTitle>Modo pré-visualização</AlertTitle>
      Neste modo você está visualizando conteúdos que ainda não foram publicados. Clique ao lado
      para sair.
    </Alert>
  </Box>
);

function AssembleLayout({
  Component = Fragment,
  Layout = Fragment,
  layoutProps = {},
  preview,
  children,
  ...restProps
}) {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    AOS.init({
      once: true,
      delay: 25,
      duration: 200,
      easing: 'ease-in-out',
    });
  }, []);

  const [themeMode, themeToggler, mountedComponent] = useDarkMode();
  useEffect(() => {
    AOS.refresh();
  }, [mountedComponent]);

  return (
    // TODO: Must work on a better darkmode that fits all pages
    <ThemeProvider theme={getTheme(Layout.name === CheckoutLayout.name ? 'light' : themeMode)}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <NotistackProvider>
        <Paper elevation={0}>
          <Layout themeMode={themeMode} themeToggler={themeToggler} {...layoutProps}>
            {children}
            {preview && <PreviewBox />}
            <Component themeMode={themeMode} {...restProps} />
          </Layout>
        </Paper>
        <CookiesNotification />
      </NotistackProvider>
    </ThemeProvider>
  );
}

AssembleLayout.propTypes = {
  Component: PropTypes.any,
  Layout: PropTypes.any,
  layoutProps: PropTypes.object,
  preview: PropTypes.bool,
  children: PropTypes.any,
};

export default AssembleLayout;
